/* START: General styles */
.max-width {
  @apply max-w-[1440px] mx-auto;
}

.padding-x {
  @apply sm:px-16 px-6;
}

.padding-y {
  @apply py-4;
}

.flex-center {
  @apply flex items-center justify-center;
}

.flex-between {
  @apply flex justify-between items-center;
}

.custom-btn {
  @apply flex flex-row relative justify-center items-center py-3 px-6 outline-none;
}
/* END: General styles */

/* START: Home styles */

.home__text-container {
  @apply flex flex-col items-start justify-start gap-y-2.5 text-black-100;
}

.home__filters {
  @apply mt-12 w-full flex-between items-center flex-wrap gap-5;
}

.home__filter-container {
  @apply flex justify-start flex-wrap items-center gap-2;
}

.home__products-wrapper {
  @apply grid 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 grid-cols-1 w-full gap-8 pt-14;
}

.home__error-container {
  @apply mt-16 flex justify-center items-center flex-col gap-2;
}
/* END: Home styles */

/* START: Car Card styles */
.product-card {
  @apply flex flex-col p-6 justify-center items-start text-black-100 bg-primary-blue-100 hover:bg-white hover:shadow-md rounded-3xl;
}

.product-card__content {
  @apply w-full flex justify-between items-start gap-2;
}

.product-card__content-title {
  @apply text-[22px] leading-[26px] font-bold capitalize;
}

.product-card__price {
  @apply flex mt-6 text-[32px] leading-[38px] font-extrabold;
}

.product-card__price-dollar {
  @apply self-start text-[14px] leading-[17px] font-semibold;
}

.product-card__price-day {
  @apply self-end text-[14px] leading-[17px] font-medium;
}

.product-card__image {
  @apply relative w-full h-40 my-3 object-contain;
}

.product-card__icon-container {
  @apply flex group-hover:invisible w-full justify-between text-grey;
}

.product-card__icon {
  @apply flex flex-col justify-center items-center gap-2;
}

.product-card__icon-text {
  @apply text-[14px] leading-[17px];
}

.product-card__btn-container {
  @apply hidden group-hover:flex absolute bottom-0 w-full z-10;
}
/* END: Car Card styles */

/* START: Car Details styles */
.car-details__dialog-panel {
  @apply relative w-full max-w-lg max-h-[90vh] overflow-y-auto transform rounded-2xl bg-white p-6 text-left shadow-xl transition-all flex flex-col gap-5;
}

.car-details__close-btn {
  @apply absolute top-2 right-2 z-10 w-fit p-2 bg-primary-blue-100 rounded-full;
}

.car-details__main-image {
  @apply relative w-full h-40  bg-cover bg-center rounded-lg;
}
/* END: Car Details styles */

/* START: Footer styles */
.footer {
  @apply flex flex-col text-black-100  mt-5 border-t border-gray-100;
}

.footer__links-container {
  @apply flex max-md:flex-col flex-wrap justify-between gap-5 sm:px-16 px-6 py-10;
}

.footer__rights {
  @apply flex flex-col justify-start items-start gap-6;
}

.footer__links {
  @apply flex-1 w-full flex md:justify-end flex-wrap max-md:mt-10 gap-20;
}

.footer__link {
  @apply flex flex-col gap-6 text-base min-w-[170px];
}

.footer__copyrights {
  @apply flex justify-between items-center flex-wrap mt-10 border-t border-gray-100 sm:px-16 px-6 py-10;
}

.footer__copyrights-link {
  @apply flex-1 flex sm:justify-end justify-center max-sm:mt-4 gap-10;
}
/* END: Footer styles */
