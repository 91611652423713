.HeroSliderLayout {
  position: relative;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 15px;
}

@media (min-width: 720px) {
  .HeroSliderLayout {
    text-align: left;
  }
}

@media (min-width: 960px) {
  .HeroSliderLayout {
    grid-column-gap: 30px;
  }
}

.HeroSliderLayout__children {
  position: relative;
  z-index: 20;
  grid-column-end: span 12;
}

@media (min-width: 720px) {
  .HeroSliderLayout__children {
    grid-column-end: span 7;
  }
}

@media (min-width: 960px) {
  .HeroSliderLayout__children {
    grid-column-end: span 6;
  }
}

.HeroSliderLayout__slider {
  position: relative;
  z-index: 10;
  margin-bottom: -50%;
  max-width: 100%;
  grid-column-end: span 12;
}

@media (min-width: 720px) {
  .HeroSliderLayout__slider {
    display: block;
  }
}

@media (min-width: 720px) {
  .HeroSliderLayout__slider {
    grid-column-end: span 5;
  }
}

@media (min-width: 960px) {
  .HeroSliderLayout__slider {
    grid-column-end: span 6;
  }
}
.Image {
  display: block;
  max-width: 100%;
}
.ImageSlider {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}

@media (min-width: 720px) {
  .ImageSlider {
    max-width: none;
  }
}

.ImageSlider__content {
  position: relative;
  padding-top: 100%;
}

.ImageSlider__slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  opacity: 0;
}

.ImageSlider__slide-appear {
  opacity: 1;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.ImageSlider__slide-enter {
  -webkit-transform: translateX(60%) scale(0.8);
  transform: translateX(60%) scale(0.8);
  opacity: 0;
}

.ImageSlider__slide-enter-active {
  -webkit-transition: opacity 0.8s ease-out, -webkit-transform 0.8s ease-in-out;
  transition: opacity 0.8s ease-out, -webkit-transform 0.8s ease-in-out;
  transition: opacity 0.8s ease-out, transform 0.8s ease-in-out;
  transition: opacity 0.8s ease-out, transform 0.8s ease-in-out,
    -webkit-transform 0.8s ease-in-out;
  -webkit-transform: none;
  transform: none;
  opacity: 1;
}

.ImageSlider__slide-enter-done {
  opacity: 1;
}

.ImageSlider__slide-exit {
  opacity: 1;
}

.ImageSlider__slide-exit-active {
  -webkit-transform: translateX(-60%) scale(0.8);
  transform: translateX(-60%) scale(0.8);
  opacity: 0;
  -webkit-transition: opacity 0.8s ease-in, -webkit-transform 0.8s ease-in-out;
  transition: opacity 0.8s ease-in, -webkit-transform 0.8s ease-in-out;
  transition: opacity 0.8s ease-in, transform 0.8s ease-in-out;
  transition: opacity 0.8s ease-in, transform 0.8s ease-in-out,
    -webkit-transform 0.8s ease-in-out;
}

.ImageSlider__slide-exit-done {
  opacity: 0;
}

@-webkit-keyframes ImageContainer-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes ImageContainer-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Image {
  display: block;
  max-width: 100%;
}

.Image:not(.Image--fit-contain):not(.Image--fit-cover) {
  height: auto;
}

.Image--inline {
  display: inline;
}

.Image--fit-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.Image--fit-cover {
  -o-object-fit: cover;
  object-fit: cover;
}
