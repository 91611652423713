.MiddleFactsBar__header {
  background-image: url("https://lithoreact.themezaa.com/assets/img/webp/home-startup-text-bg.webp");
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  line-height: 260px;
  letter-spacing: -10px;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  --ts-bg-opacity: 1;
  background-color: rgb(35, 35, 35 / var(--ts-bg-opacity));
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin-bottom: 0 !important;
  margin: 0 0 50px;
}

@media (min-width: 0px) {
  .MiddleFactsBar__header {
    line-height: 150px;
    font-size: 160px;
  }
}
@media (min-width: 600px) {
  .MiddleFactsBar__header {
    line-height: 150px;
    font-size: 160px;
  }
}
@media (min-width: 900px) {
  .MiddleFactsBar__header {
    line-height: 150px;
    font-size: 160px;
  }
}
@media (min-width: 1200px) {
  .MiddleFactsBar__header {
    line-height: 260px;
    font-size: 70px;
    height: 10rem;
    max-height: 100%;
    padding-top: 70px;
  }
}
@media (min-width: 1536px) {
  .MiddleFactsBar__header {
    line-height: 0px;
    font-size: 120px;
  }
}

.MiddleFactsBar__subheader {
  --tw-text-opacity: 1;
  color: rgb(35 35 35 / var(--tw-text-opacity));
  letter-spacing: 4px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  display: block;
  text-align: center;
}

@media (min-width: 0px) {
  .MiddleFactsBar__subheader {
    line-height: 20px;
    font-size: 14px;
  }
}
@media (min-width: 600px) {
  .MiddleFactsBar__subheader {
    line-height: 20px;
    font-size: 14px;
  }
}
@media (min-width: 900px) {
  .MiddleFactsBar__subheader {
    line-height: 20px;
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .MiddleFactsBar__subheader {
    line-height: 28px;
    font-size: 20px;
  }
}
@media (min-width: 1536px) {
  .MiddleFactsBar__subheader {
    line-height: 4px;
    font-size: 20px;
  }
}

.LeftFactsBar__header {
  letter-spacing: -3px;
  line-height: 70px;
  font-weight: 600;
  font-size: 70px;
  font-family: Poppins, sans-serif;
  color: #27ae60;
  text-align: center;
}

.LeftFactsBar__subheader {
  --tw-text-opacity: 1;
  color: rgb(35 35 35 / var(--tw-text-opacity));
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  display: block;
}

@media (min-width: 0px) {
  .LeftFactsBar__subheader {
    margin-bottom: 5px;
  }
}
@media (min-width: 600px) {
  .LeftFactsBar__subheader {
    margin-bottom: 5px;
  }
}
@media (min-width: 900px) {
  .LeftFactsBar__subheader {
    margin-bottom: 5px;
  }
}
@media (min-width: 1200px) {
  .LeftFactsBar__subheader {
    margin-bottom: 5px;
  }
}
@media (min-width: 1536px) {
  .LeftFactsBar__subheader {
    margin-bottom: 10px;
  }
}
.LeftFactsBar__subtitle {
  display: inline-block;
  margin: 0;
}
@media (min-width: 0px) {
  .LeftFactsBar__subtitle {
    width: 100%;
  }
}
@media (min-width: 600px) {
  .LeftFactsBar__subtitle {
    width: 100%;
  }
}
@media (min-width: 900px) {
  .LeftFactsBar__subtitle {
    width: 60%;
  }
}
@media (min-width: 1200px) {
  .LeftFactsBar__subtitle {
    width: 90%;
  }
}
@media (min-width: 1536px) {
  .LeftFactsBar__subtitle {
    width: 90%;
  }
}

.RightFactsBar__header {
  letter-spacing: -3px;
  line-height: 70px;
  font-weight: 600;
  font-size: 70px;
  font-family: Poppins, sans-serif;
  color: #27ae60;
  text-align: center;
}

.RightFactsBar__subheader {
  --tw-text-opacity: 1;
  color: rgb(35 35 35 / var(--tw-text-opacity));
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  display: block;
}

@media (min-width: 0px) {
  .RightFactsBar__subheader {
    margin-bottom: 5px;
  }
}
@media (min-width: 600px) {
  .RightFactsBar__subheader {
    margin-bottom: 5px;
  }
}
@media (min-width: 900px) {
  .RightFactsBar__subheader {
    margin-bottom: 5px;
  }
}
@media (min-width: 1200px) {
  .RightFactsBar__subheader {
    margin-bottom: 5px;
  }
}
@media (min-width: 1536px) {
  .RightFactsBar__subheader {
    margin-bottom: 10px;
  }
}
.RightFactsBar__subtitle {
  display: inline-block;
  margin: 0;
}
@media (min-width: 0px) {
  .RightFactsBar__subtitle {
    width: 100%;
  }
}
@media (min-width: 600px) {
  .RightFactsBar__subtitle {
    width: 100%;
  }
}
@media (min-width: 900px) {
  .RightFactsBar__subtitle {
    width: 60%;
  }
}
@media (min-width: 1200px) {
  .RightFactsBar__subtitle {
    width: 90%;
  }
}
@media (min-width: 1536px) {
  .RightFactsBar__subtitle {
    width: 90%;
  }
}
